.contact-us-page-container {
    display: flex;
    justify-content: center;
}

.form-container {
    width: 50%;
    background-color: #FFBB33;
}

.address {
    width: 50%;
    background-color: #2482A9;
}
.google-map{
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
}

/* medimum device (tablet, 992px and bellow) */
@media only screen and (max-width: 992px) {
    .contact-us-page-container {
        flex-direction: column;
    }

    .form-container {
        width: 100%;
    }

    .address {
        width: 100%;
    }
}
@media only screen and (max-width: 992px) {
    .google-map{
        height: 200px;
    }
}