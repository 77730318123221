.call-button a {
    position: fixed;
    /* padding: 13px; */
    bottom: 19px;
    right: 20px;
}

a.call-button :hover {
    translate: (1.5);
}

.contact-info-column a{
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
}
.whats{
    background-color: #128c7e;
    color: white;
    border-radius: 50px;
    width: 60px;
    height: 60px;
    text-align: center;
    padding: 5px;
    bottom: 99px !important;
}