
img {
    position: relative;
}

.carousel-caption {
    position: absolute;
    top: 40%;
}

.carousel-caption>h2 {
    font-weight: 700 !important;
    text-transform: uppercase;
}


/* MEDIA QUERY */
/* X-Small devices (portrait phones, less than 576px) */
@media only screen and (max-width: 575.98px) {
    .carousel-caption {
        top: 10%;
    }
}

/* Small devices (landscape phones, less than 768px)  */
@media only screen and (max-width: 767.98px) {
    .carousel-caption {
        top: 20%;
    }
}
.welcome-section-container {
    display: flex;
    justify-content: center;
}

.welcome-left-side-contents {
    width: 50%;
    /* border: solid 2px white; */
    height: 500px;
}

.image-container>img {
    max-width: 500px;
    height: auto;
    border-radius: 50%;
    position: absolute;;
}

.image-tag {
    background-image: linear-gradient(to right, #E8BC45, #a5810d);
    width: 150px;
    height: 150px;
    border-radius: 50%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.image-tag>h2 {
    font-weight: bold;
    color: white;
}

.image-tag>p {
    font-weight: bold;
    color: white;
}

.welcome-right-side-contents {
    width: 50%;
}

/* MEDIA QUERYY */
/* Medium devices (tablets, less than 992px) */
@media  only screen and (max-width: 991.98px) { 
    .welcome-section-container {
        flex-direction: column;
        /* align-items: center; */
    }
    .welcome-left-side-contents {
        max-width: 100%;
        height: 400px;
    }
    .welcome-right-side-contents {
        width: 100%;
    }
 }

 /* X-Small devices (portrait phones, less than 576px) */
@media only screen and (max-width: 575.98px) { 
    .image-container>img {
        max-width: 350px;
        
    }
 }
.call-button a {
    position: fixed;
    /* padding: 13px; */
    bottom: 19px;
    right: 20px;
}

a.call-button :hover {
    translate: (1.5);
}

.contact-info-column a{
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
}
.whats{
    background-color: #128c7e;
    color: white;
    border-radius: 50px;
    width: 60px;
    height: 60px;
    text-align: center;
    padding: 5px;
    bottom: 99px !important;
}

.social-media-icon{
    width: 2rem;
}



/* stander Media Query css */
/* media qury when the max screen size is 1200px */
@media only screen and (max-width: 1200px) {}

@media only screen and (max-width: 992px) {
    .social-media-container{
        display: none;
    }
}

/* media qury when the max screen size is 1200px */
@media only screen and (max-width: 768px) {
    
}

/* media qury when the max screen size is 1200px */
@media only screen and (max-width: 576px) {}

/* extra Media Query css at a specific screen*/
/* media qury when the max screen size is xxxxxxpx */
@media only screen and (max-width: 0000px) {}
.our-service-hr {
    margin: 2rem auto !important;
}

.service-items {
    display: flex;
    justify-content: center;
}

.individual-service-item-1 :hover {
    cursor: pointer;
    background-image: url(/static/media/card-background.593cdbce.jpg);
    background-repeat: no-repeat;
    object-fit: contain;
    color: white;
}

/* .service-items :hover {
    cursor: pointer;
    background-image: url("../../../background-images/card-background.jpg");
    object-fit: contain;
    color: white;
} */

.individual-item {
    background-color: black;

}

.individual-item :hover {
    cursor: pointer;
    background-image: none;
    object-fit: contain;
    color: white;
}


/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 991.98px) {
    .service-items {
        flex-direction: column;
    }

    .individual-service-item-1 :hover {
        cursor: pointer;
        background-image: none;
        background-repeat: no-repeat;
        object-fit: cover;
        color: white;
    }
}

@media only screen and (max-width: 513px) {
    .service-items {
        flex-direction: column;
    }
}
.discover-our-works-contents {
    display: flex;
    align-items: center;
}

.image-frame>img {
    width: 100%;
    height: auto;
    object-fit: contain;
    border: 1px solid white;
    border-radius: 0.8rem;
}
.showcase-image-frame>img {
    width: 100%;
    height: auto;
    object-fit: fill;
    border: 1px solid white;
    border-radius: 0.8rem;
}

/* MEDIA QEURY */
/* Medium devices (tablets, less than 992px) */
@media  only screen and (max-width: 991.98px) { 
    .image-frame>img {
        margin-top: 1rem;
    }
 }
.customer-review-container{
    background-image: url(/static/media/customer-reviews-bg.5863c92e.jpg);
    object-fit: contain;

}

.feedback-section-heading{
    padding-top: 5rem;
}
.customer-name{
    padding-bottom: 5rem;
    color: white;
}

.customer-photo{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: contain;
    margin: auto;
}
.customer-words{
    color: rgb(86, 92, 97);
}
.sectionClass {
    padding: 20px 0px 50px 0px;
    position: relative;
    display: block;
  }
  
  .fullWidth {
    width: 100% !important;
    display: table;
    float: none;
    padding: 0;
    min-height: 1px;
    height: 100%;
    position: relative;
  }
  
  
  .sectiontitle {
    background-position: center;
    margin: 30px 0 0px;
    text-align: center;
    min-height: 20px;
  }
  
  .sectiontitle h2 {
    font-size: 30px;
    color: #222;
    margin-bottom: 0px;
    padding-right: 10px;
    padding-left: 10px;
  }
  
  
  .headerLine {
    width: 160px;
    height: 2px;
    display: inline-block;
    background: #101F2E;
  }
  
  
  .projectFactsWrap{
      display: flex;
    margin-top: 30px;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  
  #projectFacts .fullWidth{
    padding: 0;
  }
  
  .projectFactsWrap .item{
    width: 25%;
    height: 100%;
    padding: 50px 0px;
    text-align: center;
  }
  
  .projectFactsWrap .item:nth-child(1){
    background: rgb(16, 31, 46);
  }
  
  .projectFactsWrap .item:nth-child(2){
    background: rgb(18, 34, 51);
  }
  
  .projectFactsWrap .item:nth-child(3){
    background: rgb(21, 38, 56);
  }
  
  .projectFactsWrap .item:nth-child(4){
    background: rgb(23, 44, 66);
  }
  
  .projectFactsWrap .item p.number{
    font-size: 40px;
    padding: 0;
    font-weight: bold;
  }
  
  .projectFactsWrap .item p{
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
    margin: 0;
    padding: 10px;
    font-family: 'Open Sans';
  }
  
  
  .projectFactsWrap .item span{
    width: 60px;
    background: rgba(255, 255, 255, 0.8);
    height: 2px;
    display: block;
    margin: 0 auto;
  }
  
  
  .projectFactsWrap .item i{
    vertical-align: middle;
    font-size: 50px;
    color: rgba(255, 255, 255, 0.8);
  }
  
  
  .projectFactsWrap .item:hover i, .projectFactsWrap .item:hover p{
    color: white;
  }
  
  .projectFactsWrap .item:hover span{
    background: white;
  }
  
  @media (max-width: 786px){
    .projectFactsWrap .item {
       flex: 0 0 50%;
    }
  }
.contact-section-container{
    display: flex;
    /* justify-content: center; */
}

.gogle-iframe{
    width: 100%;
    height: 100%;
}

.left-side-contents{
    max-width: 50%;
}
.contact-us-page-container{
    width: 100% ;
}

/* MEDIA QUERY */
/* Medium devices (tablets, less than 992px) */
@media  only screen and (max-width: 992px) { 
    .contact-section-container{
        flex-direction: column;
    }
    .left-side-contents{
        max-width: 100%;
    }
    .gogle-iframe{
        width: 100%;
        height: 350px;
    }
    
 }
.navbar-customization{
    background-color: #000000;
}
.navbar-brand{
    margin-right: 6rem;
}
.navbar-logo{
    width: 150px;
}
li>a{
    font-size: 1.2rem;
    font-weight: lighter;
}


/* MEDIA QUERY */
/* / Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 992px) {

    /* Copywrite section media query */
    .navbar-brand{
        margin-right: 1rem;
    }
  }
@media only screen and (min-width: 992px) {

    /* Copywrite section media query */
    .navbar-customization{
        padding: 1.5rem 0rem;
    }
  }
.top-container {
    background-color: #E8BC45;
    height: 50px;
    background-image: linear-gradient(to right, #E8BC45, #E53D74, #4FACD8);
    display: flex;
    align-items: center;

}

.items-container {
    display: flex;
    justify-content: space-between; 
    align-items: center;
}
.timing-contact a{
    text-decoration: none;
}
/* MEDIA QUERY */
/* Medium devices (tablets, less than 992px) */
@media  only screen and (max-width: 991.98px) { 
    .top-container {
        display: none;
    }
 }

.about-page-contents {
    display: flex;
    align-items: center;
}

.image-frame>img {
    width: 100%;
    height: auto;
    object-fit: contain;
    border: 1px solid white;
    border-radius: 0.8rem;
}

/* MEDIA QEURY */
/* Medium devices (tablets, less than 992px) */
@media  only screen and (max-width: 991.98px) { 
    .image-frame>img {
        margin-top: 1rem;
    }
 }


/* What we do section CSS */
.what-we-do-section-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.left-side-contents {
    width: 50%;
}
.left-side-contents>img {
    width: 98%;
    border-radius: 0 1rem 1rem 0;
}

.right-side-contents {
    width: 50%;
}

/* MEDIA QEURY */
/* Medium devices (tablets, less than 992px) */
@media  only screen and (max-width: 991.98px) { 
    .what-we-do-section-container {
        display: flex;
        flex-direction: column-reverse;
    }
    .left-side-contents {
        width: 100%;
    }.right-side-contents {
        width: 100%;
    }
 }
.Header {
    position: relative;
    text-align: center;
    /* color: white; */
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.centered>hr {
    height: 5px !important;
    /* color: white; */
    border-radius: 10px;
}
.hair-care-container {
    display: flex;
    justify-content: center;
}

/* Left side contents CSS */
.left-side-contents {
    width: 90%;
    padding: 1rem;
}

.heade-image>img {
    border-radius: 1rem;
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-bottom: 1rem;
}

.bullet-items {
    color: white;
}

.bullet-items>li {
    color: rgb(68, 68, 68);
}
.bottom-page-galery-image{
    width: 90%;
    height: auto;
    object-fit: contain;
}

/* right side contents CSS */
.right-side-contents {
    padding: 2rem;
    border-radius: 1rem;
    width: 30%;
    background-color: #212529;
}

.category>ul {
    color: white;
}

.side-bar-list-item {
    margin: 0rem !important;
}

.side-bar-list-item>a {
    text-decoration: none;
    font-size: 1.2rem;
    margin-left: 1rem;
    font-weight: bold;
}

.page-bottom-gallery{
    display: flex;
    justify-content: center;
}


/********************** MEDIA QUERY **********************/

/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 992px) {
    .hair-care-container {
        flex-direction: column;
    }

    .left-side-contents {
        width: 100%;
        padding: 1rem;
    }

    .heade-image>img {
        border-radius: 1rem;
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    /* right side section CSS */
    .right-side-contents {
        padding: 2rem;
        border-radius: 1rem;
        width: 100%;
        background-color: #212529;
    }
}
.hair-color-container {
    display: flex;
    justify-content: center;
}

/* Left side contents CSS */
.left-side-contents {
    width: 90% ;
    padding: 1rem;
}

.heade-image>img {
    border-radius: 1rem;
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-bottom: 1rem;
}

.bullet-items {
    color: white;
}

.bullet-items>li {
    color: rgb(68, 68, 68);
}
.bottom-page-galery-image{
    width: 90%;
    height: auto;
    object-fit: contain;
}

/* right side contents CSS */
.right-side-contents {
    padding: 2rem;
    border-radius: 1rem;
    width: 30%;
    background-color: #212529;
}

.category>ul {
    color: white;
}

.side-bar-list-item {
    margin: 1rem;
}

.side-bar-list-item>a {
    text-decoration: none;
    font-size: 1.2rem;
    margin-left: 1rem;
    font-weight: bold;
}

.page-bottom-gallery{
    display: flex;
    justify-content: center;
}


/********************** MEDIA QUERY **********************/

/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 992px) {
    .hair-color-container {
        flex-direction: column;
    }

    .left-side-contents {
        width: 100%;
        padding: 1rem;
    }

    .heade-image>img {
        border-radius: 1rem;
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    /* right side section CSS */
    .right-side-contents {
        padding: 2rem;
        border-radius: 1rem;
        width: 100%;
        background-color: #212529;
    }
}
.hair-styling-container {
    display: flex;
    justify-content: center;
}

/* Left side contents CSS */
.left-side-contents {
    width: 90% ;
    padding: 1rem;
}

.heade-image>img {
    border-radius: 1rem;
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-bottom: 1rem;
}

.bullet-items {
    color: white;
}

.bullet-items>li {
    color: rgb(68, 68, 68);
}
.bottom-page-galery-image{
    width: 90%;
    height: auto;
    object-fit: contain;
}

/* right side contents CSS */
.right-side-contents {
    padding: 2rem;
    border-radius: 1rem;
    width: 30%;
    background-color: #212529;
}

.category>ul {
    color: white;
}

.side-bar-list-item {
    margin: 1rem;
}

.side-bar-list-item>a {
    text-decoration: none;
    font-size: 1.2rem;
    margin-left: 1rem;
    font-weight: bold;
}

.page-bottom-gallery{
    display: flex;
    justify-content: center;
}


/********************** MEDIA QUERY **********************/

/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 992px) {
    .hair-styling-container {
        flex-direction: column;
    }

    .left-side-contents {
        width: 100%;
        padding: 1rem;
    }

    .heade-image>img {
        border-radius: 1rem;
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    /* right side section CSS */
    .right-side-contents {
        padding: 2rem;
        border-radius: 1rem;
        width: 100%;
        background-color: #212529;
    }
}
.skin-care-container {
    display: flex;
    justify-content: center;
}

/* Left side contents CSS */
.left-side-contents {
    width: 90% ;
    padding: 1rem;
}

.heade-image>img {
    border-radius: 1rem;
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-bottom: 1rem;
}

.bullet-items {
    color: white;
}

.bullet-items>li {
    color: rgb(68, 68, 68);
}
.bottom-page-galery-image{
    width: 90%;
    height: auto;
    object-fit: contain;
}

/* right side contents CSS */
.right-side-contents {
    padding: 2rem;
    border-radius: 1rem;
    width: 30%;
    background-color: #212529;
}

.category>ul {
    color: white;
}

.side-bar-list-item {
    margin: 0rem !important;
}

.side-bar-list-item>a {
    text-decoration: none;
    font-size: 1.2rem;
    margin-left: 1rem;
    font-weight: bold;
}

.page-bottom-gallery{
    display: flex;
    justify-content: center;
}


/********************** MEDIA QUERY **********************/

/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 992px) {
    .skin-care-container {
        flex-direction: column;
    }

    .left-side-contents {
        width: 100%;
        padding: 1rem;
    }

    .heade-image>img {
        border-radius: 1rem;
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    /* right side section CSS */
    .right-side-contents {
        padding: 2rem;
        border-radius: 1rem;
        width: 100%;
        background-color: #212529;
    }
}
.bridal-makeup-container {
    display: flex;
    justify-content: center;
}

/* Left side contents CSS */
.left-side-contents {
    width: 90% ;
    padding: 1rem;
}

.heade-image>img {
    border-radius: 1rem;
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-bottom: 1rem;
}

.bullet-items {
    color: white;
}

.bullet-items>li {
    color: rgb(68, 68, 68);
}
.bottom-page-galery-image{
    width: 90%;
    height: auto;
    object-fit: contain;
}

/* right side contents CSS */
.right-side-contents {
    padding: 2rem;
    border-radius: 1rem;
    width: 30%;
    background-color: #212529;
}

.category>ul {
    color: white;
}

.side-bar-list-item {
    margin: 0rem !important;
}

.side-bar-list-item>a {
    text-decoration: none;
    font-size: 1.2rem;
    margin-left: 1rem;
    font-weight: bold;
}

.page-bottom-gallery{
    display: flex;
    justify-content: center;
}


/********************** MEDIA QUERY **********************/

/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 992px) {
    .bridal-makeup-container {
        flex-direction: column;
    }

    .left-side-contents {
        width: 100%;
        padding: 1rem;
    }

    .heade-image>img {
        border-radius: 1rem;
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    /* right side section CSS */
    .right-side-contents {
        padding: 2rem;
        border-radius: 1rem;
        width: 100%;
        background-color: #212529;
    }
}
.image-frame{
    display: flex;
    justify-content: space-between;
    object-fit: contain;
    border: 2px solid white;
    border-radius: 1rem;
    margin-top: 1rem;
}


/* MEDIA QUERY */
/* Medium devices (tablets, less than 992px) */
@media  only screen and (max-width: 991.98px) {  }
.contact-us-page-container {
    display: flex;
    justify-content: center;
}

.form-container {
    width: 50%;
    background-color: #FFBB33;
}

.address {
    width: 50%;
    background-color: #2482A9;
}
.google-map{
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
}

/* medimum device (tablet, 992px and bellow) */
@media only screen and (max-width: 992px) {
    .contact-us-page-container {
        flex-direction: column;
    }

    .form-container {
        width: 100%;
    }

    .address {
        width: 100%;
    }
}
@media only screen and (max-width: 992px) {
    .google-map{
        height: 200px;
    }
}
.location-information {
    display: flex;
    justify-content: center;
}

.google-map{
    width: 600px;
    height: 450px;
}

.contact-form {
    display: flex;
    justify-content: center;
}

/* medimum device (tablet, 992px and bellow) */
@media only screen and (max-width: 992px) {
    .location-information {
        flex-direction: column;
    }
}
/* Small devices (landscape phones, less than 768px)  */
@media only screen and (max-width: 768px) {
    .google-map{
        width: 400px;
    }
}

/* X-Small devices (portrait phones, less than 576px) */
@media only screen and (max-width: 575.98px) { 
    .google-map{
        width: 300px;
    }
 }
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(10, 10, 10);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* p,h6{
  color: rgb(163, 163, 163);
} */

p{
  color: #666666;
}
