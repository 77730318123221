
.social-media-icon{
    width: 2rem;
}



/* stander Media Query css */
/* media qury when the max screen size is 1200px */
@media only screen and (max-width: 1200px) {}

@media only screen and (max-width: 992px) {
    .social-media-container{
        display: none;
    }
}

/* media qury when the max screen size is 1200px */
@media only screen and (max-width: 768px) {
    
}

/* media qury when the max screen size is 1200px */
@media only screen and (max-width: 576px) {}

/* extra Media Query css at a specific screen*/
/* media qury when the max screen size is xxxxxxpx */
@media only screen and (max-width: 0000px) {}