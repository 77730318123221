.Header {
    position: relative;
    text-align: center;
    /* color: white; */
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.centered>hr {
    height: 5px !important;
    /* color: white; */
    border-radius: 10px;
}