.about-page-contents {
    display: flex;
    align-items: center;
}

.image-frame>img {
    width: 100%;
    height: auto;
    object-fit: contain;
    border: 1px solid white;
    border-radius: 0.8rem;
}

/* MEDIA QEURY */
/* Medium devices (tablets, less than 992px) */
@media  only screen and (max-width: 991.98px) { 
    .image-frame>img {
        margin-top: 1rem;
    }
 }


/* What we do section CSS */
.what-we-do-section-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.left-side-contents {
    width: 50%;
}
.left-side-contents>img {
    width: 98%;
    border-radius: 0 1rem 1rem 0;
}

.right-side-contents {
    width: 50%;
}

/* MEDIA QEURY */
/* Medium devices (tablets, less than 992px) */
@media  only screen and (max-width: 991.98px) { 
    .what-we-do-section-container {
        display: flex;
        flex-direction: column-reverse;
    }
    .left-side-contents {
        width: 100%;
    }.right-side-contents {
        width: 100%;
    }
 }