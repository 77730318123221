.navbar-customization{
    background-color: #000000;
}
.navbar-brand{
    margin-right: 6rem;
}
.navbar-logo{
    width: 150px;
}
li>a{
    font-size: 1.2rem;
    font-weight: lighter;
}


/* MEDIA QUERY */
/* / Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 992px) {

    /* Copywrite section media query */
    .navbar-brand{
        margin-right: 1rem;
    }
  }
@media only screen and (min-width: 992px) {

    /* Copywrite section media query */
    .navbar-customization{
        padding: 1.5rem 0rem;
    }
  }