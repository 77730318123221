.image-frame{
    display: flex;
    justify-content: space-between;
    object-fit: contain;
    border: 2px solid white;
    border-radius: 1rem;
    margin-top: 1rem;
}


/* MEDIA QUERY */
/* Medium devices (tablets, less than 992px) */
@media  only screen and (max-width: 991.98px) {  }