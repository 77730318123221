.our-service-hr {
    margin: 2rem auto !important;
}

.service-items {
    display: flex;
    justify-content: center;
}

.individual-service-item-1 :hover {
    cursor: pointer;
    background-image: url("../../../background-images/card-background.jpg");
    background-repeat: no-repeat;
    object-fit: contain;
    color: white;
}

/* .service-items :hover {
    cursor: pointer;
    background-image: url("../../../background-images/card-background.jpg");
    object-fit: contain;
    color: white;
} */

.individual-item {
    background-color: black;

}

.individual-item :hover {
    cursor: pointer;
    background-image: none;
    object-fit: contain;
    color: white;
}


/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 991.98px) {
    .service-items {
        flex-direction: column;
    }

    .individual-service-item-1 :hover {
        cursor: pointer;
        background-image: none;
        background-repeat: no-repeat;
        object-fit: cover;
        color: white;
    }
}

@media only screen and (max-width: 513px) {
    .service-items {
        flex-direction: column;
    }
}