.contact-section-container{
    display: flex;
    /* justify-content: center; */
}

.gogle-iframe{
    width: 100%;
    height: 100%;
}

.left-side-contents{
    max-width: 50%;
}
.contact-us-page-container{
    width: 100% ;
}

/* MEDIA QUERY */
/* Medium devices (tablets, less than 992px) */
@media  only screen and (max-width: 992px) { 
    .contact-section-container{
        flex-direction: column;
    }
    .left-side-contents{
        max-width: 100%;
    }
    .gogle-iframe{
        width: 100%;
        height: 350px;
    }
    
 }