.discover-our-works-contents {
    display: flex;
    align-items: center;
}

.image-frame>img {
    width: 100%;
    height: auto;
    object-fit: contain;
    border: 1px solid white;
    border-radius: 0.8rem;
}
.showcase-image-frame>img {
    width: 100%;
    height: auto;
    object-fit: fill;
    border: 1px solid white;
    border-radius: 0.8rem;
}

/* MEDIA QEURY */
/* Medium devices (tablets, less than 992px) */
@media  only screen and (max-width: 991.98px) { 
    .image-frame>img {
        margin-top: 1rem;
    }
 }