.welcome-section-container {
    display: flex;
    justify-content: center;
}

.welcome-left-side-contents {
    width: 50%;
    /* border: solid 2px white; */
    height: 500px;
}

.image-container>img {
    max-width: 500px;
    height: auto;
    border-radius: 50%;
    position: absolute;;
}

.image-tag {
    background-image: linear-gradient(to right, #E8BC45, #a5810d);
    width: 150px;
    height: 150px;
    border-radius: 50%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.image-tag>h2 {
    font-weight: bold;
    color: white;
}

.image-tag>p {
    font-weight: bold;
    color: white;
}

.welcome-right-side-contents {
    width: 50%;
}

/* MEDIA QUERYY */
/* Medium devices (tablets, less than 992px) */
@media  only screen and (max-width: 991.98px) { 
    .welcome-section-container {
        flex-direction: column;
        /* align-items: center; */
    }
    .welcome-left-side-contents {
        max-width: 100%;
        height: 400px;
    }
    .welcome-right-side-contents {
        width: 100%;
    }
 }

 /* X-Small devices (portrait phones, less than 576px) */
@media only screen and (max-width: 575.98px) { 
    .image-container>img {
        max-width: 350px;
        
    }
 }