.hair-styling-container {
    display: flex;
    justify-content: center;
}

/* Left side contents CSS */
.left-side-contents {
    width: 90% ;
    padding: 1rem;
}

.heade-image>img {
    border-radius: 1rem;
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-bottom: 1rem;
}

.bullet-items {
    color: white;
}

.bullet-items>li {
    color: rgb(68, 68, 68);
}
.bottom-page-galery-image{
    width: 90%;
    height: auto;
    object-fit: contain;
}

/* right side contents CSS */
.right-side-contents {
    padding: 2rem;
    border-radius: 1rem;
    width: 30%;
    background-color: #212529;
}

.category>ul {
    color: white;
}

.side-bar-list-item {
    margin: 1rem;
}

.side-bar-list-item>a {
    text-decoration: none;
    font-size: 1.2rem;
    margin-left: 1rem;
    font-weight: bold;
}

.page-bottom-gallery{
    display: flex;
    justify-content: center;
}


/********************** MEDIA QUERY **********************/

/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 992px) {
    .hair-styling-container {
        flex-direction: column;
    }

    .left-side-contents {
        width: 100%;
        padding: 1rem;
    }

    .heade-image>img {
        border-radius: 1rem;
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    /* right side section CSS */
    .right-side-contents {
        padding: 2rem;
        border-radius: 1rem;
        width: 100%;
        background-color: #212529;
    }
}