.top-container {
    background-color: #E8BC45;
    height: 50px;
    background-image: linear-gradient(to right, #E8BC45, #E53D74, #4FACD8);
    display: flex;
    align-items: center;

}

.items-container {
    display: flex;
    justify-content: space-between; 
    align-items: center;
}
.timing-contact a{
    text-decoration: none;
}
/* MEDIA QUERY */
/* Medium devices (tablets, less than 992px) */
@media  only screen and (max-width: 991.98px) { 
    .top-container {
        display: none;
    }
 }
