img {
    position: relative;
}

.carousel-caption {
    position: absolute;
    top: 40%;
}

.carousel-caption>h2 {
    font-weight: 700 !important;
    text-transform: uppercase;
}


/* MEDIA QUERY */
/* X-Small devices (portrait phones, less than 576px) */
@media only screen and (max-width: 575.98px) {
    .carousel-caption {
        top: 10%;
    }
}

/* Small devices (landscape phones, less than 768px)  */
@media only screen and (max-width: 767.98px) {
    .carousel-caption {
        top: 20%;
    }
}